











import Vue from 'vue'
import {Component} from "vue-property-decorator";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";
import { Getter } from 'vuex-class';
import { LolModuleGetterTypes } from '@/store/modules/lol/types';
import { StoreNamespace } from '@/store/types';
import { LolDataDragonAssetsApi } from '@/services/RiotAssets/LolDataDragonAssetsApi';

@Component({
  components: {ResponsiveContainer}
})
export default class StatsPage extends Vue {
  @Getter(LolModuleGetterTypes.LAST_PLAYED_CHAMPION_NAME, { namespace: StoreNamespace.Lol })
  private readonly mostFrequentChampionName!: string

  @Getter(LolModuleGetterTypes.BACKGROUND_SKIN_ID, { namespace: StoreNamespace.Lol })
  private readonly skinId!: number

  private backgroundImgSrc(champion: string = this.mostFrequentChampionName): string {
    return LolDataDragonAssetsApi.championSplashImgUri(champion, this.skinId);
  }

}
